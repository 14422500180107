import MagnifyingGlass from '@components/bits/icons/MagnifyingGlass';
import MapMarker from '@components/bits/icons/MapMarker';
import PillButton from '@components/bits/PillButton';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import useWindowDimensions from '@utils/hooks/useWindowDimension';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';

// import { Subject } from 'rxjs';
// import { debounceTime } from 'rxjs/operators';
import styles from './index.module.scss';

type Props = {
  dealers?;
  filterText: string;
  filterType: string;
  changeFilterType: (string) => void;
  clearInputs;
  findClosestActive: boolean;
  sending: boolean;
  findClosest;
  changeInputHandler;
};

interface Size {
  width: number | undefined;
  height: number | undefined;
}

// const debouncer = new Subject();

const SearchModule: FunctionComponent<Props> = ({
  findClosest,
  changeInputHandler,
  changeFilterType,
  clearInputs,
  filterText = '',
  filterType = '',
  findClosestActive = false,
  sending = false,
}) => {
  const size: Size = useWindowDimensions();
  const searchRef = useRef<HTMLSpanElement>();
  const [fixed, setFixed] = useState(false);
  const fixedRef = useRef(false);
  const stateRef = useRef({ searchTop: 0 });

  useScrollPosition(({ currPos }) => {
    const { style } = searchRef.current;
    style.transform = `translate3d(0, 0, 0)`;
    updateSearchPos(currPos);
  });

  // useEffect(() => {
  //   debouncer.pipe(debounceTime(40)).subscribe((currPos) => updateSearchPos(currPos));
  // }, [size]);

  const updateSearchPos = async (currPos): Promise<void> => {
    const { offsetTop } = searchRef.current;

    if (currPos.y + offsetTop < 0 && size.width < 768 && fixedRef.current === false) {
      stateRef.current.searchTop = offsetTop;
      setFixed(true);
      fixedRef.current = true;
    } else if (fixedRef.current === true && currPos.y > -stateRef.current.searchTop) {
      setFixed(false);
      fixedRef.current = false;
    }
  };

  useEffect(() => {
    changeFilterType('Salg');
  }, []);

  const searchPlaceholder =
    size.width <= 768 ? 'Søk forhandler' : 'Søk etter din nærmeste forhandler';

  return (
    <div className={`${styles.root} ${fixed ? styles['fixed-search'] : ''}`}>
      <h2>Våre forhandlere</h2>
      <span ref={searchRef} id="mapSearch" className={styles['_input-wrapper']}>
        <input
          placeholder={searchPlaceholder}
          value={filterText || ''}
          onChange={changeInputHandler}></input>

        {sending ? <div id="loading" className="white"></div> : <MagnifyingGlass />}
      </span>

      <div className={styles['_buttons-container']}>
        <span>
          <PillButton text="Vis alle" active={false} onClick={() => clearInputs()} />
          <PillButton
            text="Forhandler"
            active={filterType === 'Salg'}
            onClick={() => changeFilterType('Salg')}
          />
          <PillButton
            text="Service"
            active={filterType === 'Verksted'}
            onClick={() => changeFilterType('Verksted')}
          />
        </span>
        <span className={styles['_locate-button']}>
          <PillButton
            text="Finn nærmeste"
            active={findClosestActive}
            onClick={findClosest}
            icon={<MapMarker color={findClosestActive ? 'white' : 'black'} />}
          />
        </span>
      </div>
    </div>
  );
};

export default SearchModule;
